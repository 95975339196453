<div class="relative">
  <h1 class="text-3xl font-medium" i18n>User management</h1>

  <div class="absolute right-0 top-0 md:right-4 md:top-0">
    <app-button
      icon="ellipsis"
      iconFill="blue"
      classes="p-3"
      (click)="actionsMenuOpen = true"
      (keydown.enter)="actionsMenuOpen = true"
    />
  </div>

  <div *ngIf="(isPending$ | async) === false" class="mt-12 w-full pb-8">
    <div
      *ngIf="users.length; else showNoResults"
      class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
    >
      <div
        class="relative w-full rounded-xl p-6 shadow bg-white"
        *ngFor="let user of users"
      >
        <div class="absolute right-4 top-4">
          <app-button
            icon="ellipsis"
            iconFill="blue"
            classes="h-10 w-10"
            (click)="userMenuOpen = user.id"
            (keydown.enter)="userMenuOpen = user.id"
          />
        </div>

        <div>
          <h2 i18n class="text-sm font-light text-gray-600">User name</h2>

          <p class="mb-5 mt-1 text-xl text-black">{{ user.username }}</p>
        </div>

        <div>
          <h2 i18n class="text-sm font-light text-gray-600">
            User phone number
          </h2>

          <p class="mb-5 mt-1 text-xl text-black">{{ user.phoneNumber }}</p>
        </div>

        <div>
          <h2 i18n class="text-sm font-light text-gray-600">User email</h2>

          <p class="mb-5 mt-1 truncate text-xl text-black">{{ user.email }}</p>
        </div>

        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Status</h2>

          <div class="mb-5 flex flex-row items-center justify-between">
            <ng-container *ngIf="user.status === 'INVITED'; else OTHER_STATUS">
              <p class="text-xl text-black" i18n>Invitation sent</p>
              <a
                aria-disabled="true"
                (keydown.enter)="resendInvitation(user.email)"
                (click)="resendInvitation(user.email)"
                (keydown.enter)="resendInvitation(user.email)"
                class="flex cursor-pointer items-center text-alpha-blue underline underline-offset-2"
                i18n
              >
                Resend

                <app-icon icon="send" class="ml-2 h-4" />
              </a>
            </ng-container>

            <ng-template #OTHER_STATUS>
              <p class="text-xl text-black">{{ user.status | titlecase }}</p>
            </ng-template>
          </div>
        </div>

        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Role</h2>

          <div class="flex flex-row items-center justify-between">
            <p class="text-xl text-black">{{ user.role | titlecase }}</p>
          </div>
        </div>

        <app-menu
          [show]="userMenuOpen === user.id"
          (closed)="userMenuOpen = ''"
        >
          <app-menu-action
            i18n-text
            text="View user details"
            (click)="viewUser(user.id)"
            (keydown.enter)="viewUser(user.id)"
          />
          <app-menu-action
            i18n-text
            text="View user jobs"
            (click)="viewUserJobs(user.id)"
            (keydown.enter)="viewUserJobs(user.id)"
          />
          <ng-container *ngIf="user.status !== 'INVITED'">
            <app-menu-action
              *ngIf="user.role === 'USER'"
              i18n-text
              text="Grant admin access"
              (click)="updateUserRole(user.id, user.username, 'ADMIN')"
              (keydown.enter)="updateUserRole(user.id, user.username, 'ADMIN')"
            />
            <app-menu-action
              *ngIf="user.role === 'ADMIN'"
              i18n-text
              text="Remove admin access"
              (click)="updateUserRole(user.id, user.username, 'USER')"
              (keydown.enter)="updateUserRole(user.id, user.username, 'USER')"
            />
          </ng-container>
          <app-menu-action
            i18n-text
            text="Remove user"
            (click)="removeUser(user.id, user.username)"
            (keydown.enter)="removeUser(user.id, user.username)"
          />
        </app-menu>
      </div>
    </div>
  </div>

  <ng-template #showNoResults class="mt-20 flex items-center justify-center">
    <h1 i18n class="text-3xl text-alpha-gray-80">No results.</h1>
  </ng-template>
</div>

<div
  *ngIf="(isPending$ | async)"
  class="mt-20 flex items-center justify-center"
>
  <app-loading />
</div>

<app-menu [show]="actionsMenuOpen" (closed)="actionsMenuOpen = false">
  <app-menu-action
    (click)="inviteUser()"
    (keydown.enter)="inviteUser()"
    i18n-text
    text="Invite regular user"
  />
  <app-menu-action
    (click)="inviteUser(true)"
    (keydown.enter)="inviteUser(true)"
    i18n-text
    text="Invite admin user"
  />
</app-menu>

<app-invite-user-modal
  [show]="showInviteModal"
  [inviteAdmin]="inviteAdmin"
  (closed)="inviteUserModalClosed()"
/>
<app-admin-access-modal
  [show]="showUpdateUserRoleModal"
  [userId]="updateUserRoleUserId"
  [userName]="updateUserRoleUserName"
  [userRole]="newUserRole"
  (closed)="updateUserRoleModalClosed()"
/>
<app-disable-user-modal
  [show]="showRemoveModal"
  [userId]="removeUserId"
  [userName]="removeUserName"
  (closed)="removeUserModalClosed()"
/>
