import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { DividerComponent } from '../../../components/divider/divider.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { UserStatus } from '../../../interfaces/status.interface';
import { UserRole } from '../../../interfaces/user.interface';
import { JobActions } from '../../../store/actions/job.actions';
import { OrganisationActions } from '../../../store/actions/organisation.actions';
import {
  selectAllOrganisationUsers,
  selectOrganisationStatePending,
} from '../../../store/selectors/organisation.selector';
import { JOBS_AND_TESTS_PAGE_URL } from '../jobs-and-tests/jobs-and-tests.page';
import { MenuComponent } from './components/menu/menu.component';
import { MenuActionComponent } from './components/menu-action/menu-action.component';
import { InviteUserModalComponent } from './modals/invite-user/invite-user.component';
import { AdminAccessModalComponent } from './user-details/modals/admin-access/admin-access.component';
import { DisableUserModalComponent } from './user-details/modals/disable-user/disable-user.component';

export const USER_MANAGEMENT_PAGE_URL = 'user-management';

@Component({
  standalone: true,
  templateUrl: './user-management.page.html',
  imports: [
    LoadingComponent,
    CommonModule,
    InviteUserModalComponent,
    ButtonComponent,
    DividerComponent,
    IconComponent,
    MenuComponent,
    MenuActionComponent,
    DisableUserModalComponent,
    AdminAccessModalComponent,
  ],
})
export class UserManagementPage implements OnInit, OnDestroy {
  public users: {
    id: string;
    status: UserStatus;
    username: string;
    email: string;
    phoneNumber: string;
    role: string;
  }[] = [];

  public actionsMenuOpen = false;
  public inviteAdmin = false;
  public newUserRole: UserRole = 'USER';
  public removeUserId = '';
  public removeUserName = '';
  public showInviteModal = false;
  public showRemoveModal = false;
  public showUpdateUserRoleModal = false;
  public updateUserRoleUserId = '';
  public updateUserRoleUserName = '';
  public userMenuOpen = '';
  public isPending$ = this.store.pipe(select(selectOrganisationStatePending));

  private organisationUsersSubscription: Subscription | null = null;

  public constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.store.dispatch(OrganisationActions.getUsers());

    this.organisationUsersSubscription = this.store
      .select(selectAllOrganisationUsers)
      .subscribe((users) => {
        this.users = users.map(({ userId, status, profile, role }) => {
          const { email, phoneNumber, firstName, lastName } = profile;
          return {
            id: userId,
            status,
            username: `${firstName} ${lastName}`,
            email,
            phoneNumber,
            role,
          };
        });
      });
  }

  public ngOnDestroy(): void {
    this.organisationUsersSubscription?.unsubscribe();
  }

  public inviteUser(admin = false): void {
    this.inviteAdmin = admin;
    this.showInviteModal = true;
  }

  public inviteUserModalClosed(): void {
    this.showInviteModal = false;
    this.inviteAdmin = false;
  }

  public removeUser(id: string, name: string): void {
    this.removeUserId = id;
    this.removeUserName = name;
    this.showRemoveModal = true;
    this.userMenuOpen = '';
  }

  public removeUserModalClosed(): void {
    this.showRemoveModal = false;
  }

  public resendInvitation(email: string): void {
    this.store.dispatch(OrganisationActions.inviteResend({ email }));
  }

  public updateUserRole(id: string, name: string, role: UserRole): void {
    this.newUserRole = role;
    this.updateUserRoleUserId = id;
    this.updateUserRoleUserName = name;
    this.showUpdateUserRoleModal = true;
    this.userMenuOpen = '';
  }

  public updateUserRoleModalClosed(): void {
    this.showUpdateUserRoleModal = false;
  }

  public async viewUser(id: string): Promise<void> {
    await this.router.navigateByUrl(
      `/user-management/${encodeURIComponent(id)}`,
    );
  }

  public async viewUserJobs(userId: string): Promise<void> {
    this.store.dispatch(
      JobActions.search({
        params: {
          user: userId,
        },
      }),
    );

    await this.router.navigateByUrl(`${JOBS_AND_TESTS_PAGE_URL}`);
  }
}
